import React from "react"

import Layout from "../components/layout"
import { Helmet } from "react-helmet"

const NotFoundPage = () => (
  <Layout>
    <Helmet>
      {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
      <title>Not Found – Purple Cow Internet 💜🐄</title>
    </Helmet>
    <center>
      <h1>NOT FOUND</h1>
      <p>Sorry, we couldn't find that page!</p>
    </center>
  </Layout>
)

export default NotFoundPage
